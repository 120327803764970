<template>
  <div class="entry">
    <v-row>
      <v-col cols="1">
        <v-icon class="entry__icon" :size="32">{{ `$vuetify.icons.${icon}` }}</v-icon>
      </v-col>
      <v-col cols="11" md="5">
        <h3 class="entry__title">{{ title }}</h3>
        <div v-if="description" class="entry__description">
          {{ description }}
        </div>
        <div class="mt-8">
          <slot name="contentAddition" />
        </div>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" md="1">
        <v-spacer />
      </v-col>
      <v-col cols="12" md="5">
        <slot name="controls" />
      </v-col>
    </v-row>
    <slot name="bottomContent" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'info',
    },
  },
})
</script>

<style lang="sass" scoped>
.entry
  div:empty
    display: none
  &__icon
    color: #65728e
    position: relative
    bottom: 3px
  &__title
    color: $c-skribbleu
    font-size: 1.125em
    margin-bottom: 1.125em
  &__description
    color: $c-grey-dark
    font-size: 0.875em
</style>
