<template>
  <div class="d-flex align-center justify-space-between">
    <label
      class="checkbox-label"
      :class="{
        'text--disabled': isDisabled,
        'checkbox-label--selected': isSelected,
      }"
      :for="checkboxId"
    >
      {{ label }}
    </label>
    <div class="d-flex align-center">
      <div v-if="isEnterpriseOnly && isDisabled" class="tier-badge">enterprise</div>
      <v-checkbox
        :id="checkboxId"
        v-model="isSelected"
        on-icon="$vuetify.icons.checkbox_active"
        off-icon="$vuetify.icons.checkbox_inactive"
        :disabled="isDisabled"
        :ripple="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    isEnterpriseOnly: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSelected: {
      get(): boolean {
        return this.value
      },
      set(newValue: boolean) {
        this.$emit('input', newValue)
      },
    },
    checkboxId(): string {
      return this.label ? this.label.replace(/ /g, '-').toLowerCase() + '-checkbox' : ''
    },
  },
})
</script>

<style lang="sass" scoped>
.checkbox-label
  display: flex
  align-items: center
  align-self: stretch
  flex-grow: 1
  padding-right: 2rem
  user-select: none
  &:not(.text--disabled)
    cursor: pointer
  &:hover:not(&--selected)
    color: $c-primary
  &--selected
    font-weight: 800

.tier-badge
  background-color: #0B9BF0
  color: $c-white
  font-size: 0.75rem
  font-weight: 800
  border-radius: 4px
  margin-right: 2rem
  padding: 0.5em 0.75em
  text-transform: uppercase
</style>
