<template>
  <div class="settings-header">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsSectionHeader',
})
</script>

<style lang="sass">
.settings-header
  padding: 0.75rem 1.5rem
  margin-bottom: 3rem
  font-weight: 800
  border-radius: 4px 4px 0 0
  border-bottom: 1px solid #DADEE6
  color: $c-grey-dark
  background-color: $c-grey-fine
</style>
