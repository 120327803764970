<template>
  <v-select
    v-model="selection"
    class="settings__select"
    solo
    append-icon="$vuetify.icons.arrow_down"
    :placeholder="placeholder"
    :items="items"
    :disabled="isDisabled"
  />
</template>

<script lang="ts">
import type { PropType } from 'vue'
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: [String, Boolean, Number] as PropType<SettingsValue | null>,
    },
    items: {
      type: Array as PropType<SettingsOption[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selection: {
      get(): SettingsValue | null {
        return this.value
      },
      set(selectedValue: SettingsOption) {
        this.$emit('input', selectedValue)
      },
    },
  },
})
</script>

<style lang="sass" scoped>
.settings__select::v-deep
  .v-select__selections
    font-size: 1rem
    padding-top: 0.75rem
    padding-bottom: 0.75rem
    .v-select__selection
      font-weight: 800
</style>
