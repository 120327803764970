<script lang="ts" setup>
import { useI18n } from 'nuxt-i18n-composable'

withDefaults(
  defineProps<{
    isFixed?: boolean
    hasUnsavedChanges?: boolean
    isLoading?: boolean
    isDisabled?: boolean
    height?: string
    offset?: string
    actionText?: string
  }>(),
  {
    height: '88px',
    offset: '0',
  }
)

const emit = defineEmits<{
  (e: 'save'): void
}>()

const { t } = useI18n()
</script>

<template>
  <div class="save-element" :class="{ 'save-element--is-fixed': isFixed }">
    <slot name="default">
      <div class="content">
        <div v-if="hasUnsavedChanges" class="d-flex align-center">
          <v-icon class="mr-4" color="info">$vuetify.icons.info</v-icon>
          <span class="font-weight-bold">
            {{ t('business.settings.info.unsaved_changes') }}
          </span>
        </div>
        <div v-else>
          {{ t('business.settings.info.no_changes') }}
        </div>
        <v-btn
          data-cy="save_button"
          color="info"
          large
          :disabled="!hasUnsavedChanges || isDisabled"
          :loading="isLoading"
          @click="emit('save')"
        >
          {{ actionText ?? t('business.settings.save') }}
        </v-btn>
      </div>
    </slot>
  </div>
</template>

<style lang="sass">
.save-element
  height: v-bind('height')
  background-color: $c-white
  border-top: 1px solid $c-grey-light
  &--is-fixed
    position: fixed
    bottom: 0
    left: v-bind('offset')
    right: 0
    padding: 0 24px
  > .content
    height: inherit
    display: flex
    align-items: center
    justify-content: space-between
    max-width: $site-width-narrow
    padding: 0 24px
    margin: 0 auto
</style>
