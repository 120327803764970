<template>
  <v-dialog v-model="isShown" max-width="600">
    <v-card data-cy="deactivateAutoDeletionDialog">
      <v-card-title>
        {{ $t('business.auto_deletion.deactivation_dialog.title') }}
      </v-card-title>
      <v-card-text>
        <p>
          {{ $t('business.auto_deletion.deactivation_dialog.subtitle') }}
        </p>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn x-large outlined color="info" @click="isShown = false">
          {{ $t('global.no_thanks') }}
        </v-btn>
        <v-spacer />
        <v-btn x-large color="error" data-cy="deactivateAutoDeletionButton" @click="onDeactivate">
          {{ $t('business.auto_deletion.deactivate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isShown: {
      get(): boolean {
        return this.value
      },
      set(newValue: boolean): void {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    onDeactivate() {
      this.$emit('deactivate')
      this.isShown = false
    },
  },
})
</script>
